// // segments with ionlabel without resultgroup in ionitemdivider
//$ios-summary-height : 41px;
//$md-summary-height : 50px;
//// resultgroup in ionitemdivider
//$ios-summary-height : 62px;
//$md-summary-height : 67px;
// resultgroup in ionitem

.map-background {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    display: none;
  }
}

.outer-scrollable {
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // above the map controls
  z-index: 2;

  &.full-map {
    pointer-events: none;
  }

  ion-back-button {
    position: fixed;
    top: 0;
    pointer-events: all;
  }
}

.directions-details {
  min-height: 100%;
  pointer-events: all;

  .directions-details-summary {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid lightgray;
    // above the other details
    z-index: 2;
  }

  .mode-icon > svg {
    // 16 seems to match ionicons
    // we really want max 16x16, which works in ff but not chrome
    width: 16px;
    height: 16px;
  }

  //summary {
  //  list-style : none;
  //
  //  &::-webkit-details-marker {
  //    display : none;
  //  }
  //}

  //::slotted(ion-label[slot="start"]) {
  ion-label[slot="start"] {
    flex: 0 auto;
    //align-self: start;
  }

  @media (min-width: 444px) {
    .segment-main {
      display: flex;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 16px 0;
    margin: 0 -16px;
  }
}

.segment-container {
  // ion-item (ionic component) has some by default spacing like padding
  // below styles will override default css
  --padding-start: 0;
  --inner-padding-end: 0;
  padding: 0 5px;

  .segment-main {
    display: flex;
    flex-direction: column;

    .segment-summary {
      display: flex;
      flex-direction: row;

      .segment-icon {
        .mode-icon {
          height: 25px;
          width: 25px;
          margin-right: 5px;
        }
        .mode-icon > svg {
          // 16 seems to match ionicons
          // we really want max 16x16, which works in ff but not chrome
          width: 25px;
          height: 25px;
        }
      }

      .segment-info {
        flex: 1;
        // background-color: yellow;

        .time-info {
          // background-color: red;
          display: flex;
          justify-content: space-between;

          .info-left {
            .totalStops {
              color: grey;
            }
          }
          .info-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .accessible {
              margin: 0;
            }
          }
        }
      }
    }

    .duration {
      color: grey;
    }

    .details-container {
      .segment-details {
        display: inline;
        margin-left: 30px;

        .summary-arrow {
          .icon {
            margin-left: 5px;
            margin-bottom: -4px;
          }
        }
      }

      // it'll hide dots when details are expanded

      .segment-details ~ .stop-list {
        .stops-container:not(:first-child):not(:last-child) {
          display: none;
        }
        .stops-container:nth-child(1), .stops-container:nth-child(2) {
          .dots {
            display: none;
          }
        }
      }
      
      .segment-details[open] ~ .stop-list {
        .stops-container .dots {
          display: none;
        }
        .stops-container:not(:first-child):not(:last-child) {
          display: block;
        }
      }


        // TODO: hide dots when details are open

      .summary-right {
        display: inline;
        float: right;
      }

      .stop-list {
        margin-left: 30px;

        .stops-container {
          .dots {
            margin-top: 8px;
            margin-bottom: 8px;
            cursor: pointer;

            .dot {
              height: 5px;
              width: 5px;
              background-color: grey;
              border-radius: 50%;
              // width of icon above this dot is 22px and width of dot is 5px that's why margin-left is 6px (22/2 - 5)
              margin: 2px 0;
              margin-left: 6px;
            }
          }
        }
      }
    }

    .segment-title {
      font-weight: 500;
      margin: 5px 0px;
      font-size: 16px;
      margin-left: 30px;
      white-space: normal;
    }
  }
}

.stops {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 8px;
  .icon {
    height: 22px;
    width: 22px;
    min-width: 22px;
    margin-right: 5px;
  }
}

.note-section {
  padding-left: 27px;

  .partial-accessible-note {
    font-size: 14px;
    color: grey;
    white-space: pre-wrap;
  }
}

.stops:first-child {
  margin-top: 10px;
}
