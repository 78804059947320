.layer-control {
  position: relative;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // above the geocoder.  can't get it above the page header
  z-index: 1;
}

.details {
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
  // above the backdrop
  z-index: 2;
}

.details .card {
    max-width: calc(100vw - 24px - 24px);
    max-height: calc(100vh - 128px - 12px);
    overflow-y: auto;
}
