#tutorial-page {
  ion-toolbar {
    --background: transparent;
    --border-color: transparent;
  }

  ion-slides {
    height: 100%;
  }

  .slides-md,
  .slides-ios {
    --bullet-background: black;
  }

  .swiper-slide {
    display: block;
  }

  .slide-title {
    margin-top: -2rem;
  }

  .slide-image {
    max-height: 50%;
    max-width: 60%;
    margin: 100px 0 60px;
    pointer-events: none;
  }

  b {
    font-weight: 500;
  }

  .tutorial-slide {
    max-width: 700px;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .travel-info-row {
    display: flex;
    justify-content: center;

    .icon-right-margin {
      margin-right: 5px;
    }

    .icon-left {
      width: 30%;
      text-align: right;
    }

    .text-right {
      flex:1;
      text-align: left;
    }

  }

  .bottom-icon-grid {
    max-width: 400px;
  }

  p {
    padding: 0 40px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);

    b {
      color: var(--ion-text-color, #000000);
    }
  }
}

.slide-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.swiper-pagination-bullets {
  //Lower to next/skip/continue buttons(.slide-buttons) so that it doesn't conflict when we click on the buttons
  z-index: -1;
}
