
.time-options {
    .prevent-keyboard {
        --keyboard-offset: 0px !important;               //Force keyboard(in ios) to be hidden state always.
    }
    .date-time-icon {
        position: absolute;
        right: 15px;
        bottom: 3px;
    }
    
    ion-row {
        flex: 1 1 auto;
    }
        
    ion-grid {
        height: 100%;  
        display: flex;
        flex-direction: column;
    }
    input {
        width: 100%; 
        appearance: none;
        background: transparent;
        border: transparent;
        outline: none;
        z-index: 1;
        font-size: 20px;
    }
    
    input::-webkit-calendar-picker-indicator {
        opacity: 0;
        width: 100%;
        min-height: 48px;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    
    ion-icon {
        z-index: -1;
    }
    
    .stacked-label {
        position: relative;
        width: 100%;
        font-size: 13px;
        display: inline-block;
    }
    
}