.resultMain {
  padding: 10px;
  width: 100%;
  align-items: center;

  .journeyDetail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .detail {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .priceLabel {
        font-size: 17px;
        font-weight: 500;
        // margin-bottom: 5px;
      }
    }

    //will apply only to the first detail element
    .detail:first-child {
      align-items: flex-start;
    }
  }

  .segmentSection {
    overflow: auto;
  }
}

.accessible {  
  margin-bottom: 5px;
}
