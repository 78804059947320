.geocoder {
  position: relative;
}

ion-refresher.refresher-native {
  z-index: 4; // above from-location
}

.from-location {
  z-index: 3; // above to-location
}

.to-location {
  z-index: 2; // above rest of IonContent - 1 doesn't work when ionic renders for iOS although I can't see which element is causing a problem
}

.mode-durations {
  // off for screenshots, on for release
  overflow-x: auto;
  white-space: nowrap;

  .mode-icon {
    margin: -4px;
    margin-right: 8px;

    > svg {
      // we really want max 20x20, which works in ff but not chrome
      width: 20px;
      height: 20px;
    }
  }
}

.query-options {
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
}

.summary-section{
  // ion-item (ionic component) has some by default spacing like padding
  // below styles will override default css 
  --padding-start: 0;
  --inner-padding-end: 0;
}

.route-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}
