ion-menu ion-content {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --menu-list-container: 50px;
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}


/* Remove background transitions for switching themes */
ion-menu ion-item {
  --transition: none;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

/* styles to manage icons in list-header */
ion-list-header ion-icon{
  font-size: 24px;
  margin-right: 10px;
}

/*
 * Material Design Menu
*/


ion-menu.md ion-list-header {
  padding-left: 18px;
  padding-right: 18px;

  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 450;
}

ion-menu.md ion-item {
  --padding-start: 18px;

  margin-right: 10px;

  border-radius: 0 50px 50px 0;

  font-weight: 500;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-list-header,
ion-menu.md ion-item ion-icon {
  color: var(--ion-color-step-650, #5f6368);
}

ion-menu.md ion-list:not(:last-of-type) {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

.menu-list-container {
  min-height: calc(100% - var(--menu-list-container) + var(--padding-bottom) + var(--padding-top) + var(--offset-bottom) + var(--offset-top) + var(--keyboard-offset));
}

.last-updated-date{
  padding-top: .3rem;
  display: flex;
  justify-content: center;
  margin-bottom: -1rem;
  font-size: 13px;
}

ion-menu.md .last-updated-date {
  border-top: 1px solid var(--ion-color-step-150, #d7d8da);
}

.inner-scroll {
  padding-bottom: calc(var(--padding-bottom) + var(--keyboard-offset) + var(--offset-bottom)) i !important;
}
/*
 * iOS Menu
*/
ion-menu.ios ion-list-header {
  padding-left: 16px;
  padding-right: 16px;

  margin-bottom: 8px;
}


ion-menu.ios ion-item {
  --padding-start: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

.item .menu-items-label{
  white-space: normal;
}


