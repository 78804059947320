.segments {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.segment {
  border-radius: 5px;

  // segment details styles when trip contain only 1 segment
  &.segment-detail[data-single-segment-trip="true"]{
    height: 30px;
    margin-bottom: 0;
  }

  // accessibility information bar styles when trip contain only 1 segment
  &.accessibility-info[data-single-segment-trip="true"]{
    margin-top: 0;
  }

  > svg {
    width: 20px;
    height: 20px;
  }

  .mode-icon{
    height: 20px;
    width: 20px;
  }

  .mode-icon > svg {
    // 17 seems to match ionicons
    // we really want max 17x17, which works in ff but not chrome
    width: 17px;
    height: 17px;
  }
  

  //segment-details
  .segment-detail {
    height: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .segment-detail-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 15px;
    }
  }

  //accessibility-info-bar
  .accessibility-info {
    height: 20px;
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding-right: 17px;

    &[data-is-last-segment-in-trip="true"]{
      padding-right: 0;
    }

    .icon{
      margin: 0 2px;
      min-width: 20px;
    }

    .icon > svg{
      height: 16px;
      width: 16px;
    }

    .accessible-line{
      width: 100%;
      height: 3px;
      border-radius: 10px;
      background-color: grey;

      &[data-accessible="true"]{
        background-color: #0099ff;
      }

      &[data-accessible="false"]{
        background-color: red;
      }
    }
  }
}

.arrowIcon {
  min-width: 16px;
}

.icon-n-number {
  display: flex;
  align-items: center;

  > svg {
    width: 20px;
    height: 20px;
  }

  .service-number {
    margin-left: 3px;
    white-space: nowrap;
  }
}
