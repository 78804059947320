.sticky-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #e6e6e6;
  padding-top: 7px;
  padding-bottom: 7px;
  .header-section {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    color: var(--ion-text-color, #000000);
    div {
      margin-left: 10px;
      margin-right: 10px;
      align-self: center;
    }
    ion-icon.mode-icon {
      width: 22px;
      height: 22px;
    }
    > svg {
      height: 22px;
      width: 22px;
    }
  }

  .stopName {
    font-size: 14px;
    font-weight: 500;
    flex: 1;
  }
}

.sticky-divider.md {
  padding-right: 5px;
}

.accessible {
  margin: 0px;
  align-self: flex-start;
  margin-top: 5px;
}

.stops {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.service-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  ion-icon.mode-icon {
    width: 22px;
    height: 22px;
  }
  > svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
}
