.accessible {
  background-color: grey;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 120px;
  margin-left: 5px;

  ::before {
    content: "Unknown";
  }

  &[data-accessible="true"] {
    background-color: #0099ff;

    ::before {
      content: "Accessible";
    }
  }

  &[data-accessible="false"] {
    background-color: red;

    ::before {
      content: "Inaccessible";
    }
  }

  &[data-accessible="partial"]{
    background-color:rgb(237, 185, 5);

    ::before{
      content: "See Notes";
    }
  }

  .icon {
    height: 14px;
    width: 16px;
    fill: white;
    margin-right: 5px;
  }
}

.icon > svg {
  height: 22px;
  width: 22px;
}
