.map-canvas {
  position         : absolute;

  height           : 100%;
  width            : 100%;

  background-color : transparent;

  // the initial load sometimes has the wrong size so we hide it until it's loaded and resized
  opacity          : 0;
  transition       : opacity 250ms ease-in;

  &.show-map {
    opacity : 1;
  }
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  /*so the search bar fills the screen width*/
  margin : 0;
}

.mapboxgl-ctrl-layer {
  height      : 29px;
  width       : 29px;
  line-height : 29px;
  /*center the 24px icon*/
  padding     : 2.5px;
  /* override mapbox so the backdrop works */
  transform: none;
}

.mapboxgl-ctrl-bottom-right {
  /*so the info bar doesn't go beyond the left edge*/
  max-width : 100%;
}

.mapboxgl-ctrl.mapboxgl-ctrl-info:empty {
  display : none;
}

.mapboxgl-ctrl.mapboxgl-ctrl-info {
  margin  : 10px;
  width   : calc(100% - 20px);
  padding : 0 4px;
}

.mapboxgl-ctrl-info-place-name {
  white-space   : nowrap;
  text-overflow : ellipsis;
  overflow      : hidden;
}

.mapboxgl-ctrl-info-action-directions {
  display       : inline-block;
  padding       : 0 10px;
  border-radius : 24px;
  --background  : var(--ion-color-primary, #3880ff);
  color         : white;
}
